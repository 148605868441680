var languageConfigs = {
  user: {
    identityId: "Số CMND",
    profile: "Thông tin cá nhân",
    setting: "Cài đặt",
    logout: "Thoát",
    phone: "Số điện thoại",
    fullName: "Họ tên",
    dob: "Ngày sinh",
    gender: "Giới tính",
    email: "Email",
    address: "Địa chỉ",
    occupation: "Nghề nghiệp",
    country: "Quốc gia",
    call: "Gọi",
    password: "Mật khẩu",
    profileUrl: "Đường liên kết URL",
  },
  home: {
    welcome: "Chào mừng",
    haveaniceday: "Chúc bạn một ngày tốt lành!",
    report: "Thông số",
    event: "Lịch hẹn",
    episode: "Lượt khám",
    notification: "Thông báo",
    device_list: "Danh sách thiết bị",
    device_connected: "Thiết bị đã kết nối",
    stograte_cabinet: "Tủ bảo quản",
    holter: "Holter",
    autoclave: "Máy hấp",
  },
  auth: {
    or: "Hoặc",
    emailorphone: "Email / SĐT",
    login: "Đăng nhập",
    signup: "Đăng ký",
    verifyphone: "Vui lòng nhập số điện thoại",
    sendcode: "Gửi mã",
    alreadyhaveaccount: "Bạn đã có tài khoản?",
    donthaveaccount: "Bạn chưa có tài khoản?",
  },
  register: {
    editlogo: "Chọn logo",
    verifyCode: "Nhập mã xác thực",
    becomedoctor: "Đăng ký làm bác sĩ",
    registerclinic: "Đăng ký phòng khám",
    registeraccount: "Đăng ký tài khoản",
    register: "Đăng ký",
    continue: "Tiếp tục",
    additionalinfo: "Ngày sinh & Giới tính",
    male: "Nam",
    female: "Nữ",
    completed: "Hoàn thành",
    startusingapp: "Bắt đầu sử dụng",
    message1:
      "Thông tin này được sử dụng nhằm cung cấp trải nghiệm tốt hơn cho người dùng.",
    message2: "Cảm ơn bạn đã đăng ký tài khoản Sandrasoft!",
    message3:
      "Chúng tôi sẽ liên hệ bạn trong vòng 24h để kiểm chứng thông tin. Bạn đã có thể sử dụng Sandrasoft từ lúc này. Xin cảm ơn.",
  },
  common: {
    all: "Tất cả",
    uncategorized: "Chưa phân loại",
    doctorNote: "Ghi chú của bác sĩ",
    reconnect: "Kết nối lại",
    reconnectMessage: "Đồng ý tái kết nối lại thiết bị",
    subscribeInvitation: "Lời mời kết nối",
    inputDeviceID: "Nhập mã thiết bị",
    hour: "Giờ",
    manualMode: "Tự chỉ định",
    calibsMethod: "Phương thức đo",
    series: "Đồ thị",
    low: "Thấp",
    normal: "Tốt",
    high: "Cao",
    veryHigh: "Rất Cao",
    trial: "Lượt đo",
    statistics: "Thống kê",
    profile: "Hồ sơ",
    connectedDevice: "Thiết bị đã kết nối",
    undefined: "Lượt đo ẩn danh",
    finishEpisode: "Kết thúc đo",
    finishEpisodeConfirm: "Bạn có muốn kết thúc lượt đo này?",
    cannotFindAnyDevice:
      "Không thể tìm thấy thiết bị nào, xin vui lòng thử lại sau.",
    deleteDevice: "Bạn có muốn xoá thiết bị này?",
    deleteInvitation: "Bạn có chắc muốn xoá lời mời kết nối này?",
    acceptSubscribe:
      "Đã mở kết nối. Bây giờ {0} có thể xem lượt đo mới của bạn.",
    cancelSubscribe:
      "Đã đóng kết nối. Bây giờ {0} không còn được xem lượt đo mới của bạn",
    areYouSureToClose:
      "Bạn có chắc muốn đóng lại lượt đo, dữ liệu sẽ không dc lưu?",
    close: "Đóng",
    showQr: "Hiện QR cá nhân",
    personalQr: "QR cá nhân",
    searchByPatient: "Tìm theo tên hoặc SDT",
    chooseAll: "Chọn tất cả",
    scanQr: "Scan QR",
    patient: "Bệnh nhân",
    findDevice: "Dò thiết bị",
    scanQrDevice: "Scan QR",
    confirmConnectDevice: "Bạn có muốn kết nối với thiết bị ",
    cuffPressure: "Áp suất cuff",
    connectDevice: "Kết nối thiết bị",
    bleNotAvailable: "Bluetooth không được hỗ trợ trên thiết bị này",
    heartRate: "Nhịp tim",
    sys: "Tâm thu",
    dias: "Tâm trương",
    clinicalNote: "Ghi chú lâm sàng",
    saveAndFinish: "Lưu & hoàn thành",
    successMessage: "Yêu cầu được xử lý thành công",
    configEpisode: "Thiết lập lượt đo",
    maxInflatePressure: "Áp suất nén tối đa (mmHg)",
    calibsPerHour: "Số lần đo mỗi giờ",
    valueCannotBeEmpty: "Giá trị (*) không được để trống!",
    errorMessage: "Yều cầu xử lý không thành công. Vui lòng thử lại sau.",
    episodeName: "Đặt tên cho lượt đo",
    averageStats: "Chỉ số trung bình",
    bloodPressure: "Huyết áp",
    chart: "Biểu đồ dữ liệu",
    times: "lần",
    date: "Ngày",
    back: "Quay lại",
    search: "Tìm kiếm",
    checkin: "Tiếp nhận",
    appoint: "Đặt hẹn",
    choose: "Chọn",
    selecttoview: "Chọn thẻ để xem",
    selectdatetime: "Chọn ngày & giờ",
    create: "Tạo mới",
    info: "Thông tin",
    detail: "Thông tin chi tiết",
    select: "Chọn",
    datetime: "Ngày & giờ",
    confirm: "Xác nhận",
    confirmed: "Xác nhận",
    delete: "Hủy",
    cancel: "Hủy",
    save: "Lưu",
    finish: "Hoàn thành",
    selectfile: "Chọn tệp",
    today: "Hôm nay",
    at: "Lúc",
    invite: "Thêm",
    user: "Người dùng",
    notprovide: "Chưa có dữ liệu",
    nodata: "Chưa có dữ liệu",
    awesomenodata: "Bạn chưa có thông báo mới.",
    lastvisits: "Lượt khám gần đây",
    verifyUpdate: "Đang khởi chạy ứng dụng, xin vui lòng đợi trong giây lát...",
    serverunavailable: "Server đang được cập nhật. Vui lòng quay lại sau.",
    backtohome: "Về trang chủ",
    timeStart: "Giờ bắt đầu",
    timeEnd: "Giờ kết thúc",
    examcontent: "Nội dung khám",
    clinicaldata: "Ghi chú của bác sĩ",
    account: "Tài khoản",
    report: "Báo cáo",
    month: "Tháng",
    rebooking: "Hẹn tái khám",
    open: "Mở trang",
    title: "Tiêu đề",
    headline: "Mô tả ngắn",
    share: "Chia sẻ",
    unshare: "Ngừng chia sẻ",
    confirmShare:
      "Chia sẻ thông tin này cho người khác xem. Bạn có chắc muốn tiếp tục?",
    confirmUnshare:
      "Khi ngừng chia sẻ người khác sẽ không còn xem được thông tin này. Bạn có chắc muốn tiếp tục?",
    confirmDeleteContact:
      "Xoá số này ra khỏi danh bạ, nhưng data không bị mất đi. Bạn có chắc muốn tiếp tục?",
    change: "Đổi",
    oldPassword: "Mật khẩu cũ",
    newPassword: "Mật khẩu mới",
    retypePassword: "Nhập lại mật khẩu mới",
    changePasswordFailMessage: "Thay mật khẩu không thành công",
    changePasswordSuccessMessage:
      "Thay mật khẩu thành công. Vui lòng đăng nhập lại",
    waitingStateConnecting: "Đang liên hệ...",
    waitingStateRinging: "Đang đổ chuông...",
    waitingStateCalling: "Đang gọi...",
    waitingStateNoAnswer: "Không trả lời.",
    conversationStart: "Khởi đầu cuộc trò chuyện",
    datapoints: "Số liệu đo",
    allData: "Tất cả dữ liệu",
    chooseLanguage: "Chọn ngôn ngữ",
    waitNextTurn: "Chờ lượt tiếp theo",
    confirmConnect: "Bạn có muốn kết nối",
    sync: "Tối ưu hoá dữ liệu",
    phoneNumberError: "Số điện thoại không hợp lệ",
    youhave: "Bạn còn",
    accountremain: "tài khoản nữa",
    youhavereachyouruserslimits:
      "Bạn đã đạt giới hạn số lượng tài khoản có thể kết nối là",
    realtime: "Thời gian thực",
    next_measurement_time: "Thời gian đo tiếp theo",
  },
  nav: {
    home: "Trang chủ",
    history: "Dữ liệu",
    setting: "Tài khoản",
  },
  error: {
    phonealreadyinuse:
      "Số điện thoại này đã được sử dụng. Xin vui lòng đăng nhập.",
    verifycodefailed: "Không thể gửi code đến số điện thoại này.",
    retrysendcode: "Thử lại với số khác",
    bodyMovement: "Đừng cử động.",
    noHandInCuff: "Không có tay trong vòng.",
    cuffLoose: "Cuff không đủ chặt.",
    irregularPulse: "Nhịp bất thường.",
    pulseExceed: "Nhịp cao hơn ngưỡng.",
    pulseLower: "Nhịp thấp hơn ngưỡng.",
    improperPosition: "Sai tư thế đo.",
    overshoot: "Đừng cử động.",
    measurementFailed: "Đo không được.",
    maxdistanceFailed: "Giới hạn đo vượt ngưỡng.",
    noSysDia: "Không đo được.",
    periodFailed: "Thời gian đo không đúng.",
    unknownError: "Lỗi không xác định.",
    errorCode: "Mã lỗi",
    errorOccured: "Có lỗi xảy ra",
    deviceNameError: "Tên thiết bị không đúng.",
    cannotFindAccount: "Không tìm thấy tài khoản",
    noInternet: "Không có kết nối internet",
    pinEmptyError: "Pin dưới 20%, vui lòng sạc thêm để sử dụng!",
    deviceCodeNotRecognized:
      "Mã thiết bị không đúng. Vui lòng sử dụng thiết bị được kiểm định.",
  },
  tbq: {
    statusText: "Mã máy",
    ma_may: "Số máy",
    may_nen: "Máy nén",
    quat_lanh: "Quạt giàn lạnh",
    quat_nong: "Quạt giàn nóng",
    cua_may: "Vị trí của cửa",
    nhiet_do_trong_tu: "Nhiệt độ trong tủ",
    nhiet_do_moi_truong: "Nhiệt độ môi trường",
    do_am_moi_truong: "Độ ẩm môi trường",
    nhiet_do_cai_dat: "Nhiệt độ cài đặt",
    thoi_gian_mo_tu: "Thời gian cài đặt mở tủ",
    thoi_gian_cai_dat_bao_ve_block: "Thời gian cài đặt bảo vệ block",
    dong_hoat_dong_cua_block: "Dòng hoạt động của block",
    hien_thi_loi: "Hiển thị cảnh báo khi có lỗi",
    chay: "Chạy",
    dung: "Dừng",
    dong: "Đóng",
    mo: "Mở",
    offline: "Thiết bị đang offline. Vui lòng kiểm tra kết nối!",
    online: "Thiết bị đang hoạt động",
    lastOnline: "Lần cuối online",
  },
};

export default languageConfigs;
