<template>
  <div style="align-items: center" class="bottomChatBot d-flex pl-2 pr-2">
    <v-form @submit.prevent="sendMessage" style="width: 100%">
      <v-text-field
        class="chatInp ma-2"
        outlined
        hide-details
        placeholder="Tin nhắn"
        v-model="messageContent"
        prepend-inner-icon="mdi-message-text-outline"
        dense
      ></v-text-field>
      <!-- <v-btn
      class="btnMicrophone p0"
      elevation="1"
      icon
      rounded
      @click="sendMessage"
    >
      <v-icon :color="messageContent ? 'primary' : 'grey'">
        mdi-send-outline
      </v-icon>
    </v-btn> -->
      <!-- <v-btn disabled class="btnMicrophone p0" elevation="2" icon rounded>
      <v-icon class="iconMicro"> mdi-microphone-outline </v-icon>
    </v-btn> -->
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      messageContent: "",
    };
  },
  mounted() {},
  methods: {
    sendMessage() {
      this.$emit("send-message", this.messageContent);
      this.messageContent = "";
    },
  },
};
</script>

<style></style>
