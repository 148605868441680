import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: { name: "home" },
  },
  {
    path: "/monitor",
    component: () => import("@/components/PrimaryLayoutFullWidth"),
    redirect: { name: "monitor" },
    children: [
      {
        path: "/",
        name: "monitor",
        meta: {
          title: "Monitor Page",
          requireAuth: true,
        },
        component: () => import("@/views/monitor/Monitor"),
      },
    ],
  },
  {
    path: "/home",
    component: () => import("@/components/PrimaryLayout"),
    redirect: { name: "home" },
    meta: {},
    children: [
      {
        path: "/",
        name: "home",
        meta: {
          title: "Home Page",
          requireAuth: true,
        },
        component: () => import("@/views/home/Home"),
      },
      {
        path: "/setting",
        name: "setting",
        meta: {
          title: "Account Page",
          requireAuth: true,
        },
        component: () => import("@/views/Setting"),
      },
      {
        path: "/history",
        name: "history",
        meta: {
          title: "Episode Page",
          requireAuth: true,
        },
        component: () => import("@/views/history/History"),
      },
      {
        path: "/profile/:id",
        name: "profile",
        meta: {
          title: "Profile Page",
          requireAuth: true,
        },
        component: () => import("@/views/profile/Profile"),
      },
      {
        path: "/history/:id",
        name: "detail-history",
        meta: {
          title: "Detail Page",
          requireAuth: true,
        },
        component: () => import("@/views/history/Detail"),
      },
      //public profile
      {
        path: "/public/:id",
        name: "public-profile",
        meta: {
          title: "Public Profile Page",
        },
        component: () => import("@/views/PublicProfile"),
      },
      {
        path: "/tbq/:id",
        name: "tbq-profile",
        meta: {
          title: "TBQ device",
        },
        component: () => import("@/components/tbq/TbqDetail"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/auth"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

import store from "@/stores";
export const isAuthenticated = () => {
  return store.getters["Authen/isAuthenticated"];
};

// beforeEach for each route when run
router.beforeEach((to, from, next) => {
  // check meta data that requireAuth or not
  const requireAuth = to.matched.some(
    (childRoute) => childRoute.meta.requireAuth
  );
  // check Authenticate if having
  if (requireAuth && !isAuthenticated()) {
    next({ name: "login" });
  } else {
    if (to.name == "login" && isAuthenticated()) {
      next({ name: "home" });
    }
    next();
  }
});

// afterEach to do something when navigate success
router.afterEach((to) => {
  document.title = to.meta.title ?? "App";
});
export default router;
