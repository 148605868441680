var languageConfigs = {
  user: {
    identityId: "Indentity ID",
    profile: "Peronal Profile",
    setting: "Setting",
    logout: "Logout",
    phone: "Phone",
    fullName: "Full Name",
    dob: "Birthdate",
    gender: "Gender",
    email: "Email",
    address: "Address",
    occupation: "Occupation",
    country: "Country",
    call: "Call",
    password: "Password",
    profileUrl: "Profile URL",
  },
  home: {
    welcome: "Welcome",
    haveaniceday: "Have a nice day at work!",
    report: "Report",
    event: "Event",
    episode: "Episode",
    notification: "Notification",
    device_list: "Device list",
    device_connected: "Device connected",
    stograte_cabinet: "Stograte Cabinet",
    holter: "Holter",
    autoclave: "Autoclave",
  },
  auth: {
    or: "Or",
    emailorphone: "Email / Phone",
    login: "Login",
    signup: "Signup",
    verifyphone: "Verify your phone number",
    sendcode: "Send code",
    alreadyhaveaccount: "Already have an account?",
    donthaveaccount: "Dont have an account?",
  },
  register: {
    editlogo: "Edit logo",
    verifyCode: "Verify Code",
    becomedoctor: "Register doctor account",
    registerclinic: "Register Clinic",
    registeraccount: "Register Account",
    register: "Register",
    continue: "Continue",
    additionalinfo: "Additional Info",
    male: "Male",
    female: "Female",
    completed: "Completed",
    startusingapp: "Start using app",
    message1:
      "These information is used to provide best experience and services for our users.",
    message2: "Thank you for signing up at Sandrasoft!",
    message3:
      "We will contact you within 24h to verify. In the meatime you can start using our apllication.",
  },
  common: {
    all: "All",
    uncategorized: "Uncategorized",
    doctorNote: "Doctor note",
    reconnect: "Confirm Re-connect",
    reconnectMessage: "Confirm to re-connect device",
    subscribeInvitation: "Subscribe Invitation",
    inputDeviceID: "Input Device ID",
    hour: "Hour",
    manualMode: "Manual mode",
    calibsMethod: "Calibration method",
    series: "Series",
    low: "Low",
    normal: "Normal",
    high: "High",
    veryHigh: "So High",
    trial: "Measure",
    statistics: "Statistics",
    profile: "Profile",
    connectedDevice: "Previously connected devices",
    undefined: "Anonymous episodes",
    finishEpisode: "Finish episode",
    finishEpisodeConfirm: "Do you want to finish this episode?",
    cannotFindAnyDevice: "Cannot find any device. Please try again later",
    deleteDevice: "Are you sure to delete this device?",
    deleteInvitation: "Are you sure to delete this invitation?",
    acceptSubscribe: "Connection opened. Now {0} can see your new data.",
    cancelSubscribe:
      "Connection closed. Now {0} can no longer see your new data.",
    areYouSureToClose:
      "Are you sure you want to close, data will not be saved?",
    close: "Close",
    showQr: "Show personal QR",
    personalQr: "Personal QR",
    searchByPatient: "Search by name or phone",
    chooseAll: "Choose all",
    scanQr: "Scan QR",
    patient: "Patient profile",
    findDevice: "Find device",
    scanQrDevice: "Scan QR",
    confirmConnectDevice: "Would you like to connect to device ",
    cuffPressure: "Cuff pressure",
    connectDevice: "Connect device",
    bleNotAvailable: "Bluetooth not supported in this device",
    heartRate: "Heart rate",
    sys: "Systolic",
    dias: "Diastolic",
    clinicalNote: "Clinical note",
    saveAndFinish: "Save & finish",
    successMessage: "Action completed successfully!",
    configEpisode: "Configuration",
    maxInflatePressure: "Maximum inflation pressure (mmHg)",
    calibsPerHour: "Calibrarion per hour",
    valueCannotBeEmpty: "Value cannot be empty!",
    errorMessage: "Action completion failed. Please try again later",
    episodeName: "Episode Name",
    averageStats: "Average statistics",
    bloodPressure: "Blood pressure",
    chart: "Chart data",
    times: "times",
    date: "Date",
    back: "Back",
    search: "Search",
    checkin: "Check-in",
    appoint: "Appointment",
    choose: "Choose",
    selecttoview: "Select an item to view",
    selectdatetime: "Select date & time",
    create: "Create",
    info: "Information",
    detail: "Detailed information",
    select: "Select",
    datetime: "Date & time",
    confirm: "Confirm",
    confirmed: "Confirmed",
    delete: "Delete",
    cancel: "Cancel",
    save: "Save",
    finish: "Finish",
    selectfile: "Select file",
    today: "Today",
    at: "At",
    invite: "Invite",
    user: "User",
    notprovide: "Not provided",
    nodata: "No data found",
    awesomenodata: "Awesome! No notification yet.",
    lastvisits: "Latest episodes",
    verifyUpdate: "Starting application. Please wait a moment...",
    serverunavailable: "Server is under maintainance. Please come back later.",
    backtohome: "Back to home",
    timeStart: "Time start",
    timeEnd: "Time end",
    examcontent: "Examination content",
    clinicaldata: "Clinical note for doctor",
    account: "Account",
    report: "Report",
    month: "Month",
    rebooking: "Re-appointment",
    open: "Open website",
    title: "Title",
    headline: "Headline",
    share: "Share",
    unshare: "Unshare",
    confirmShare:
      "Share this information to be viewed by other people. Do you wish to continue?",
    confirmUnshare:
      "Unshare this information, people cannot see this information. Do you wish to continue?",
    confirmDeleteContact:
      "Remove this phone from your contact, but data will not be erased. Do you wish to continue?",
    change: "Change",
    oldPassword: "Old password",
    newPassword: "New password",
    retypePassword: "Re-type new password",
    changePasswordFailMessage: "Change password failed!",
    changePasswordSuccessMessage:
      "Change password success! Please login again.",
    waitingStateConnecting: "Connecting...",
    waitingStateRinging: "Ringing...",
    waitingStateCalling: "Calling...",
    waitingStateNoAnswer: "No anwser.",
    conversationStart: "Start of conversation",
    datapoints: "Data points",
    allData: "All data points",
    chooseLanguage: "Choose language",
    waitNextTurn: "Wait for next turn",
    confirmConnect: "Do you want to connect with",
    sync: "Optimize datapoints",
    phoneNumberError: "Phone number not valid",
    youhave: "You have",
    accountremain: "accounts remain",
    youhavereachyouruserslimits: "You have reached your account limits of",
    realtime: "Realtime",
    next_measurement_time: "Next measurement time",
  },
  nav: {
    home: "Home",
    history: "Episodes",
    setting: "Setting",
  },
  error: {
    phonealreadyinuse: "Phone number already in use, please use it to login!",
    verifycodefailed: "Cannot send verification code to this number!",
    retrysendcode: "Retry with a different phone number",
    bodyMovement: "Dont move or talk.",
    noHandInCuff: "No hand in cuff",
    cuffLoose: "Cuff loose.",
    irregularPulse: "Irregular pulse.",
    pulseExceed: "Pulse exceed upper limit.",
    pulseLower: "Pulse lower than limit.",
    improperPosition: "Improper position.",
    overshoot: "Dont move or talk.",
    measurementFailed: "Unknown error.",
    maxdistanceFailed: "Max distance failed.",
    noSysDia: "No data detected.",
    periodFailed: "Standard period detection failed.",
    unknownError: "Unknown error.",
    errorCode: "Error code",
    errorOccured: "Error Occured",
    deviceNameError: "Device name not correct.",
    cannotFindAccount: "Cannot find account info.",
    pinEmptyError: "Battery is less than 20%. Please charge device to use!",
    noInternet: "No internet connection. Please check your connection!",
    deviceCodeNotRecognized:
      "Device code is not recognized! Please use an authorized unit.",
  },
  tbq: {
    statusText: "Device Model",
    ma_may: "Serial Number",
    may_nen: "Compressor",
    quat_lanh: "Cooling fan",
    quat_nong: "Heating fan",
    cua_may: "Door location",
    nhiet_do_trong_tu: "Temp inside",
    nhiet_do_moi_truong: "Temp environment",
    do_am_moi_truong: "Humid environment",
    nhiet_do_cai_dat: "Temp configured",
    thoi_gian_mo_tu: "Time configured to open door",
    thoi_gian_cai_dat_bao_ve_block: "Time configured to protect block",
    dong_hoat_dong_cua_block: "Current of block",
    hien_thi_loi: "Configured to show error",
    chay: "Running",
    dung: "Stopped",
    dong: "Closed",
    mo: "Open",
    offline: "Device is offline. Please check connection!",
    online: "Device is working normally.",
    lastOnline: "Last online",
  },
};

export default languageConfigs;
