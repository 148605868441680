export const GenderType = {
  Male: 1,
  Female: 2,
};

export const QueueStatus = {
  Active: 0,
  Success: 1,
  Deleted: -1,
};

export const BleSeverity = {
  Severe: 1,
  High: 2,
  Normal: 3,
  Low: 4,
};

export const EventName = {
  onDatapointReceived: "onDatapointReceived",
  onEpisodeDatapoints: "onEpisodeDatapoints",
  onEpisodeFinished: "onEpisodeFinished",
};

export const PieChartColorHexCode = {
  Low: "#00BCD4",
  Normal: "#66BB6A",
  High: "#FFA726",
  SoHigh: "#F06292",
};
