import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "@/plugins/helpers";
import { GenderType } from "../plugins/constants";

function getStoredUser() {
  var userStr = getLocalStorage("BLE_APP_USER_OBJECT");
  if (userStr) {
    return JSON.parse(userStr);
  }
  return {
    id: "",
    phone: "",
    fullName: "",
    gender: GenderType.Female,
  };
}

function getStoredAccountLevel() {
  return getLocalStorage("BLE_APP_ACCOUNT_LEVEL") || 1;
}

const state = {
  user: getStoredUser(),
  accountLevel: getStoredAccountLevel(),
};

const getters = {
  getUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return (state.user.id && state.user.phone && state.user.fullName) || false;
  },
  getAccountLevel() {
    return state.accountLevel;
  },
};

const mutations = {
  SET_ACCOUNT_LEVEL(state, payload) {
    payload = payload || 0;
    state.accountLevel = payload;
    setLocalStorage("BLE_APP_ACCOUNT_LEVEL", payload);
  },
  SET_USER_PROFILE(state, payload) {
    state.user = payload;
    var strUser = JSON.stringify(state.user);
    setLocalStorage("BLE_APP_USER_OBJECT", strUser);
  },
  SET_ID(state, payload) {
    state.user.id = payload;
    var strUser = JSON.stringify(state.user);
    setLocalStorage("BLE_APP_USER_OBJECT", strUser);
  },
  SET_PHONE(state, payload) {
    state.user.phone = payload;
    var strUser = JSON.stringify(state.user);
    setLocalStorage("BLE_APP_USER_OBJECT", strUser);
  },
  SET_FULLNAME(state, payload) {
    state.user.fullName = payload;
    var strUser = JSON.stringify(state.user);
    setLocalStorage("BLE_APP_USER_OBJECT", strUser);
  },
  SET_GENDER(state, payload) {
    state.user.gender = payload;
    var strUser = JSON.stringify(state.user);
    setLocalStorage("BLE_APP_USER_OBJECT", strUser);
  },
  SET_USER(state, payload) {
    state.user = payload;
    var strUser = JSON.stringify(state.user);
    setLocalStorage("BLE_APP_USER_OBJECT", strUser);
  },
  LOGOUT(state) {
    removeLocalStorage("BLE_APP_ACCOUNT_LEVEL");
    removeLocalStorage("BLE_APP_USER_OBJECT");
    state.user = getStoredUser();
  },
};

export default {
  namespaced: "Authen",
  state,
  mutations,
  getters,
};
